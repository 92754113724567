import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Empty } from "antd";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../store/actions/AuthActions";

const Documents = () => {
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  const handleClose = () => {
    setShow(false);
    setInputError(false);
  };
  const handleShow = () => setShow(true);
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  // Documents
  // let  = "";
  const [isAnyActive, setIsAnyActive] = useState("");
  const [doc, setDoc] = useState([]);
  const getReqDocuments = async () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "required-documents/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.documents) {
          setDoc(result.documents);
          setLoad(false);

          // Calculate isAnyActive after setting the documents
          const isActive = result.documents.some(
            (document) => document.isActive === true
          );
          setIsAnyActive(isActive);
        } else if (result.message === "unauthorized" || result.message === "token expire") {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoad(false);
      });
  };

  const [DocumentName, setDocumentName] = useState("");
  const [InputError, setInputError] = useState(false);

  const createDocument = async () => {
    setInputError(false);
    if (DocumentName === "") {
      setInputError(true);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        name: DocumentName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          "required-documents/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.message === "successfully created document") {
            getReqDocuments();
            setShow(false);
            toast.success(`${result.message}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDocumentName("");
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const updateDocuments = (el) => {
    // Calculate how many documents are currently active
    const activeDocumentsCount = doc.filter(
      (document) => document.isActive
    ).length;

    // If there's only one active document and the user is trying to deactivate it
    if (activeDocumentsCount === 1 && el.isActive) {
      swal("Error", "At least one document must be active.", "error");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var raw = JSON.stringify({
      isActive: el.isActive === false ? true : false,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `required-documents/edit/${el.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message === "unauthorized" || result.message === "token expire") {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        } else if (result.message) {
          swal(
            el.isActive === false ? "Activated" : "Deactivated",
            "Updated Successfully!",
            "success"
          );
          getReqDocuments();
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getReqDocuments();
  }, []);

  return (
    <div>
      {/* Verification Documents */}
      <div className="flex flex-col gap-[17px] ">
        <h6 className="font-semibold text-[25px] text-black">Document</h6>
        <div className="flex flex-col gap-2.5 bg-white rounded-xl p-3 relative">
          <div className="mt-4">
            {load ? (
              <Spinner animation="border" />
            ) : doc && doc.length > 0 ? (
              doc.map((el, i) => (
                <div
                  className="form-check custom-checkbox mb-2 px-4  p-2 border-b"
                  key={i}
                >
                  <input
                    type="checkbox"
                    className="form-check-input border-1 border-[#0aa1dd]"
                    id={el.name}
                    value={el.id}
                    checked={el.isActive}
                    name="subscriptionGroup"
                    onClick={() => updateDocuments(el, doc)}
                  />
                  <label
                    className="form-check-label text-[14px] text-[#545454] font-semibold"
                    htmlFor={el.name}
                  >
                    {el.name}
                  </label>
                </div>
              ))
            ) : (
              <Empty />
            )}
          </div>
          <div className="absolute top-2 right-3">
            <button className="mb-2 " onClick={handleShow}>
              <i className="bi bi-plus-square-fill text-[#0aa1dd] text-lg font-semibold"></i>
            </button>
          </div>
        </div>
      </div>
      {/* Document Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Verification Document.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="document">Enter document name:</label>
          <div className="relative">
            <input
              type="text"
              className={`${
                InputError === true
                  ? "border border-red-500 placeholder:text-red-500"
                  : "border border-gray-200"
              } rounded-lg p-2 w-full`}
              placeholder="eg. driving License"
              onChange={(e) => setDocumentName(e.target.value)}
            />
            {InputError === true && (
              <div className="absolute top-2 right-2">
                <i className="bi bi-info-circle text-red-500"></i>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
            onClick={handleClose}
            onChange={(e) => setDocumentName(e.target.value)}
          >
            Close
          </button>
          <button
            className="bg-[#0aa1dd] text-white py-2.5 px-5 rounded-lg"
            onClick={createDocument}
          >
            Add Document
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Documents;
