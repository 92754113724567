import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const RefreshContext = createContext();

export const TokenContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    return storedUserDetails ? storedUserDetails.accessToken : null;
  });

  useEffect(() => {
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const expiresIn = decodedToken.exp * 1000; // Convert to milliseconds
      const timeToRefresh = expiresIn - Date.now() - 60000; // 1 minute before expiration

      // console.log(expiresIn - Date.now() - 60000);

      const refreshToken = () => {
        const myHeaders = new Headers();

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
          credentials: "include",
        };

        fetch(
          process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "auth/refresh",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.accessToken) {
              // Update local storage
              const storedUserDetails = JSON.parse(
                localStorage.getItem("userDetails")
              );
              storedUserDetails.accessToken = result.accessToken;
              localStorage.setItem(
                "userDetails",
                JSON.stringify(storedUserDetails)
              );

              // Update state
              setAccessToken(result.accessToken);
            }
          })
          .catch((error) => console.error(error));
      };

      const interval = setTimeout(() => {
        refreshToken();
      }, timeToRefresh);

      return () => {
        clearTimeout(interval);
      };
    }
  }, [accessToken]);

  return (
    <RefreshContext.Provider value={{ accessToken }}>
      {children}
    </RefreshContext.Provider>
  );
};

export const useRefreshToken = () => useContext(RefreshContext);
