// import React from "react";

// import Documents from "./Documents";
// import Subscription from "./Subscription";
// import Theme from "./Theme";
// import CheckList from "./CheckList";

// const SuperAdminSetting = () => {
//   return (
//     <div className="flex  gap-[17px]">
//       <div className="flex flex-col">
//       <Documents />
//       <div className="flex flex-col md:flex-col gap-10">
//         <div className="w-full md:w-full lg:w-1/2">
//           <CheckList />
//         </div>
//         </div>
//         <div className="w-full md:w-full lg:w-1/2">
//           <Subscription />
//         </div>
//       </div>
//       {/* <Theme /> */}
//     </div>
//   );
// };

// export default SuperAdminSetting;

import React from "react";

import Documents from "./Documents";
import Subscription from "./Subscription";
import Theme from "./Theme";
import CheckList from "./CheckList";

// const SuperAdminSetting = () => {
//   return (
//     <div className="flex    justify-between gap-[17px]">
//       <div className="flex flex-col w-full lg:w-4/6">
//         <Documents />
//         <div className="">
//          <CheckList />
//         </div>
//       </div>
//       <div className="flex flex-col  w-full lg:w-2/3">
//         <Subscription />
//       </div>
//       {/* <Theme /> */}
//     </div>
//   );
// };

const SuperAdminSetting = () => {
  return (
    <div style={{
       transform: 'scale(.85)',
      transformOrigin: '0 0',
     width:'90vw',
      height:''
      }}>    
      <div className="flex  flex-wrap  justify-between gap-[17px]">
      <div className=" w-full lg:w-6/6">
      <Documents/>
      </div>
      <div className=" w-full lg:w-6/6">
      <Subscription/>
      </div>
      <div className=" w-full lg:w-6/6">
      <CheckList/>
      </div>
    </div>
    </div>

  );
};

export default SuperAdminSetting;
