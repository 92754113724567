import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ConfigProvider, Empty, Pagination, Tabs, Tooltip } from "antd";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaRegCopy } from "react-icons/fa";
import { Logout } from "../../../../store/actions/AuthActions";
const { TabPane } = Tabs;

const RequirementList = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  const location = useLocation();
  const [req, setReq] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reqParam = queryParams.get("req");
    setReq(reqParam);
  }, [location.search, req]);

  const [CurrentTab, setCurrentTab] = useState();

  useEffect(() => {
    setCurrentTab(req ? "2" : "1");
  }, [req]);

  // const req = queryParams.get("req");
  let Navigate = useNavigate();
  const onChange = (key) => {
    // console.log(key);
    setCurrentTab(key);
    if (key === "2") {
      Navigate("/requirement-form-list?req=true");
    } else {
      Navigate("/requirement-form-list");
    }
  };

  // Registration
  const [Data, setData] = useState();
  const [RegistrationLoading, setRegistrationLoading] = useState(true);
  const [isEmpty, setisEmpty] = useState(false);
  const [url, setUrl] = useState("");

  const handleRegisterPaginate = (value, size) => {
    setRegisterationPage(value);
  };

  const [RegisterationPaginate, setRegisterationPaginate] = useState();
  const [RegisterationLimit, setRegisterationLimit] = useState(10);
  const [RegisterationPage, setRegisterationPage] = useState(1);

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const getAdminDashboardData = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `registrations?limit=${RegisterationLimit}&page=${RegisterationPage}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log("Data"+JSON.stringify(result));
        setData(result.registrations);
        setRegisterationPaginate(result.total);
        if (result.registrations) {
          setRegistrationLoading(false);
        } else if (
          result.message === "Unauthorized!" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        } else if (result.registrations.length < 0) {
          setisEmpty(true);
          setRegistrationLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
    setRegistrationLoading(false);
  }, [accessToken.accessToken, RegisterationLimit, RegisterationPage]);

  // Send Form
  const [IsLoading, setIsLoading] = useState(false);
  const handleSendForm = async (id) => {
    console.log("ID: " + id);
    setIsLoading((prevLoadingState) => ({ ...prevLoadingState, [id]: true }));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var raw = JSON.stringify({
      registrationId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "requirements/create",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("Result:" + result);

        if (
          result.message === "Requirement form has been sent through E-mail"
        ) {
          toast.success("Message has been sent to user", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getAdminDashboardData();
          setIsLoading((prevLoadingState) => ({
            ...prevLoadingState,
            [id]: false,
          }));
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        } else {
          toast.error(`${result.message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading((prevLoadingState) => ({
            ...prevLoadingState,
            [id]: false,
          }));
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Requirement form API
  const [reqForm, setReqForm] = useState([]);
  const [RequirementPaginate, setRequirementPaginate] = useState();
  const [RequirementLimit, setRequirementLimit] = useState(10);
  const [RequirementPage, setRequirementPage] = useState(1);

  const handleRequirementPaginate = (value, size) => {
    setRequirementPage(value);
  };

  const RequirementForm = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `requirements?limit=${RequirementLimit}&page=${RequirementPage}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setReqForm(result.requirements);
        setRequirementPaginate(result.total);
        if (result.requirements) {
          setIsLoading(false);
        } else if (
          result.message === "Unauthorized!" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    RequirementForm();
    getAdminDashboardData();
  }, [
    accessToken.accessToken,
    getAdminDashboardData,
    RequirementLimit,
    RequirementPage,
  ]);

  // Check List of Requirement

  const updateCheckList = (id, status) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const raw = JSON.stringify({
      isCompleted: status === true ? false : true,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `requirements/checklists/update/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.message === "Successfully updated") {
          swal("Checked", result.message, "success");
          RequirementForm();
        }
      })
      .catch((error) => console.error(error));
  };

  //copy button
  const handleCopyClick = (url) => {
    const textarea = document.createElement("textarea");
    textarea.value = url;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand("copy");

    document.body.removeChild(textarea);
    toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [MainLoad, setMainLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setMainLoad(false);
    }, 1000);
  }, []);

  return (
    <>
      {MainLoad ? (
        <div
          className="flex justify-center item-center w-full"
          style={{ height: "80vh" }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Fragment>
          <div className="flex flex-col gap-10 mb-10">
            {/* Registration Table 1 */}
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#0aa1dd",
                  fontSize: "19px",
                  fontWeightStrong: "500",
                  lineWidthBold: 3,
                },
              }}
            >
              <Tabs
                activeKey={CurrentTab}
                onChange={onChange}
                tabBarStyle={{ fontWeight: "600" }}
              >
                <TabPane tab="Registrations" key="1">
                  <div className="flex flex-col gap-4 animatedView overflow-scroll">
                    {/* <h4 className="text-[#0aa1dd] text-[20px] font-semibold">
                  Registrations
                </h4> */}
                    <div className="p-3 bg-white rounded-lg">
                      <div className="relative overflow-x-auto">
                        <table className="w-full text-sm rtl:text-right text-gray-500 text-center">
                          <thead className="text-xs text-white uppercase bg-[#0aa1dd]">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Sr No.
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Mobile Number
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Email
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Actions
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Requirement Form Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Data && Data.length > 0 ? (
                              Data.map((el, i) => {
                                const serialnumber =
                                  (RegisterationPage - 1) * RegisterationLimit +
                                  (i + 1);
                                return (
                                  <tr
                                    className="bg-white text-[12px] font-normal text-[#545454] border-b"
                                    key={i}
                                  >
                                    <th className="px-6 py-3 font-normal">
                                      {serialnumber}
                                    </th>
                                    <th className="px-6 py-3 font-normal">
                                      {el.User.fullname}
                                    </th>
                                    <td className="px-6 py-3">
                                      {el.User.mobileNumber}
                                    </td>
                                    <td className="px-6 py-3">
                                      {el.User.email}
                                    </td>
                                    <td className="px-6 py-3 flex justify-center">
                                      {el.status === "submitted" ? (
                                        <div className="p-2 capitalize rounded-md bg-green-600 text-white text-center text-xs font-semibold w-[98px]">
                                          {el.status}
                                        </div>
                                      ) : el.status === "form sent" ? (
                                        <div className="flex items-center gap-2">
                                          <div className="flex items-center gap-2">
                                            <div className="p-2 bg-orange-600 rounded-md font-semibold text-white text-xs">
                                              {el.status}
                                            </div>
                                            <ConfigProvider
                                              theme={{
                                                token: {
                                                  fontSize: 12,
                                                },
                                              }}
                                            >
                                              <Tooltip
                                                title={`Copy requirement form link`}
                                                color="#0aa1dd"
                                                className="text-sm"
                                              >
                                                <button
                                                  onClick={() => {
                                                    console.log(
                                                      process.env
                                                        .REACT_APP_PAYMENT_GATEWAY_BASE_URL +
                                                        `requirements/${el?.Requirement?.id}`
                                                    );
                                                    handleCopyClick(
                                                      `https://pre.paidswiftly.com/requirements/${el?.Requirement?.id}`
                                                    );
                                                  }}
                                                  className="flex items-center gap-1 bg-[#0aa1dd] p-2 rounded-md"
                                                >
                                                  <FaRegCopy
                                                    className="text-[#fff]"
                                                    size={12}
                                                  />
                                                </button>
                                              </Tooltip>
                                            </ConfigProvider>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <button
                                            className="p-2 rounded-md bg-[#0aa1dd] text-white text-center text-xs font-semibold w-[98px]"
                                            onClick={() =>
                                              handleSendForm(el.id)
                                            }
                                            disabled={IsLoading[el.id]}
                                          >
                                            {IsLoading[el.id] ? (
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              "Send Form"
                                            )}
                                          </button>
                                        </>
                                      )}
                                    </td>
                                    <td className="px-6 py-3">
                                      {el.status === "submitted" ? (
                                        <h6 className="font-normal text-green-600 text-sm">
                                          Form Submitted{" "}
                                          {/* <span>
                                        <i className="bi bi-check2-all ml-1 text-base"></i>
                                      </span> */}
                                        </h6>
                                      ) : el.status === "form sent" ? (
                                        <h6 className="font-normal text-orange-500 text-sm">
                                          Form Sent
                                          {/* <span>
                                        <i className="bi bi-stopwatch ml-2 text-base"></i>
                                      </span> */}
                                        </h6>
                                      ) : (
                                        <h6 className="font-normal text-[#0aa1dd] text-sm">
                                          Form To Be Send
                                          {/* <span>
                                        <i className="bi bi-info-circle ml-2 text-base"></i>
                                      </span> */}
                                        </h6>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <ConfigProvider>
                                    <Empty className="my-5 text-sm" />
                                  </ConfigProvider>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="flex justify-end">
                          <ConfigProvider
                            theme={{
                              token: {
                                colorPrimary: "#0aa1dd",
                                fontSize: 14,
                              },
                            }}
                          >
                            <Pagination
                              defaultCurrent={RegisterationPage}
                              total={RegisterationPaginate}
                              pageSize={RegisterationLimit}
                              showSizeChanger
                              onShowSizeChange={(val, limit) =>
                                setRegisterationLimit(limit)
                              }
                              className="my-3"
                              onChange={handleRegisterPaginate}
                            />
                          </ConfigProvider>
                        </div>
                        {RegistrationLoading && (
                          <div className="h-20 flex justify-center items-center">
                            <div className="absolute">
                              <Spinner animation="border" />
                            </div>
                          </div>
                        )}
                        {isEmpty && (
                          <div className="h-20 flex justify-center items-center">
                            <div className="absolute">
                              <p className="font-semibold text-red-500">
                                No Data Found
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
                {/* Table 2 */}
                <TabPane tab="Requirement Forms" key="2">
                  <div className="flex flex-col gap-4 animatedView">
                    <div className="p-3 bg-white rounded-lg">
                      <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                          <thead className="text-xs text-white uppercase bg-[#0aa1dd]">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Sr No.
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Company Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Mobile Number
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Email
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Status
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Checklist
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {reqForm && reqForm.length > 0 ? (
                              reqForm.map((el, i) => {
                                const serialnumber =
                                  (RequirementPage - 1) * RequirementLimit +
                                  (i + 1);
                                return (
                                  <tr
                                    className="bg-white text-[12px] font-normal text-[#545454] border-b"
                                    key={i}
                                  >
                                    <th className="px-6 py-3 font-normal">
                                      {serialnumber}
                                    </th>
                                    <th className="px-6 py-3 font-normal">
                                      {el.Registration.User.fullname}
                                    </th>
                                    <th className="px-6 py-3 font-normal">
                                      {el.companyName === null
                                        ? "Not Mentioned"
                                        : el.companyName}
                                    </th>
                                    <td className="px-6 py-3">
                                      {el.Registration.User.mobileNumber
                                        ? el.Registration.User.mobileNumber
                                        : "Not Mentioned"}
                                    </td>
                                    <td className="px-6 py-3">
                                      {el.Registration.User.email}
                                    </td>
                                    <td className="px-6 py-3">
                                      {el.status === "submitted" ||
                                      el.status.includes("Progress") ? (
                                        <div className="bg-[#0aa1dd] p-1.5 text-white rounded-[18px] flex gap-2 justify-center w-28 shadow">
                                          <i className="bi bi-clock-history"></i>
                                          <span className="text-xs">
                                            {el.status}
                                          </span>
                                        </div>
                                      ) : el.status.includes("progress") ||
                                        el.status.includes("pending") ||
                                        el.status.includes("Pending") ? (
                                        <div
                                          className={`bg-red-500 text-white flex justify-center gap-2 rounded-[18px] p-1.5 ${
                                            el.status.includes("progress") ||
                                            el.status.includes("Pending")
                                              ? "w-48"
                                              : "w-28"
                                          }  shadow`}
                                        >
                                          <i className="bi bi-dot animate-ping"></i>
                                          <span className="capitalize text-xs">
                                            {el.status}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="bg-green-500 text-white p-1.5 rounded-[18px] w-40 flex justify-center gap-2 shadow">
                                          <i className="bi bi-check2-all"></i>
                                          <span className="capitalize text-xs">
                                            {el.status.replace("_", " ")}
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                    <td className="px-6 py-3 flex flex-col gap-2">
                                      {el.RequirementChecklists.map(
                                        (check, i) => {
                                          return (
                                            <div key={i}>
                                              {check.Checklist?.name ===
                                              "Payment Received" ? (
                                                <span className="flex flex-row gap-2 items-center">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input cursor-pointer"
                                                    name=""
                                                    id={check.id}
                                                    checked={check.isCompleted}
                                                    onChange={() =>
                                                      Navigate(
                                                        `/${el.id}/${el.Registration.User.fullname}/create-account`
                                                      )
                                                    }
                                                    disabled={check.isCompleted}
                                                  />
                                                  <span>
                                                    {check?.Checklist?.name}
                                                  </span>
                                                </span>
                                              ) : (
                                                <span className="flex flex-row gap-2 items-center">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input cursor-pointer"
                                                    name=""
                                                    id={check.id}
                                                    checked={check.isCompleted}
                                                    disabled={
                                                      check.isCompleted === true
                                                    }
                                                    onChange={() =>
                                                      updateCheckList(
                                                        check.id,
                                                        check.isCompleted
                                                      )
                                                    }
                                                  />
                                                  <span>
                                                    {check?.Checklist?.name}
                                                  </span>
                                                </span>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </td>
                                    <td className="px-6 py-3 cursor-pointer">
                                      <Link
                                        to={`/${el.id}/${el.Registration.User.fullname}/create-account`}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                        >
                                          <path
                                            d="M4.5 2.25C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5V13.5C2.25 14.0967 2.48705 14.669 2.90901 15.091C3.33097 15.5129 3.90326 15.75 4.5 15.75H13.5C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5V11.25C15.75 10.9516 15.8685 10.6655 16.0795 10.4545C16.2905 10.2435 16.5766 10.125 16.875 10.125C17.1734 10.125 17.4595 10.2435 17.6705 10.4545C17.8815 10.6655 18 10.9516 18 11.25V13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18H4.5C3.30653 18 2.16193 17.5259 1.31802 16.682C0.474106 15.8381 0 14.6935 0 13.5V4.5C0 3.30653 0.474106 2.16193 1.31802 1.31802C2.16193 0.474106 3.30653 0 4.5 0H6.75C7.04837 0 7.33452 0.118527 7.5455 0.329505C7.75647 0.540484 7.875 0.826631 7.875 1.125C7.875 1.42337 7.75647 1.70952 7.5455 1.92049C7.33452 2.13147 7.04837 2.25 6.75 2.25H4.5ZM11.25 2.25C10.9516 2.25 10.6655 2.13147 10.4545 1.92049C10.2435 1.70952 10.125 1.42337 10.125 1.125C10.125 0.826631 10.2435 0.540484 10.4545 0.329505C10.6655 0.118527 10.9516 0 11.25 0H16.875C17.1734 0 17.4595 0.118527 17.6705 0.329505C17.8815 0.540484 18 0.826631 18 1.125V6.75C18 7.04837 17.8815 7.33452 17.6705 7.5455C17.4595 7.75647 17.1734 7.875 16.875 7.875C16.5766 7.875 16.2905 7.75647 16.0795 7.5455C15.8685 7.33452 15.75 7.04837 15.75 6.75V3.84075L12.0465 7.5465C11.9419 7.6511 11.8177 7.73407 11.6811 7.79068C11.5444 7.84729 11.3979 7.87642 11.25 7.87642C11.1021 7.87642 10.9556 7.84729 10.8189 7.79068C10.6823 7.73407 10.5581 7.6511 10.4535 7.5465C10.3489 7.4419 10.2659 7.31773 10.2093 7.18106C10.1527 7.0444 10.1236 6.89792 10.1236 6.75C10.1236 6.60208 10.1527 6.4556 10.2093 6.31894C10.2659 6.18227 10.3489 6.0581 10.4535 5.9535L14.1593 2.25H11.25Z"
                                            fill="#545454"
                                          />
                                        </svg>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <ConfigProvider>
                                    <Empty className="my-5 text-sm" />
                                  </ConfigProvider>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* Pagination */}
                        <div className="flex justify-end">
                          <ConfigProvider
                            theme={{
                              token: {
                                colorPrimary: "#0aa1dd",
                                fontSize: 14,
                              },
                            }}
                          >
                            <Pagination
                              defaultCurrent={RequirementPage}
                              total={RequirementPaginate}
                              pageSize={RequirementLimit}
                              showSizeChanger
                              onShowSizeChange={(val, size) =>
                                setRequirementLimit(size)
                              }
                              className="my-3"
                              onChange={handleRequirementPaginate}
                            />
                          </ConfigProvider>
                        </div>
                        {RegistrationLoading && (
                          <div className="h-20 flex justify-center items-center">
                            <div className="absolute">
                              <Spinner animation="border" />
                            </div>
                          </div>
                        )}
                        {isEmpty && (
                          <div className="h-20 flex justify-center items-center">
                            <div className="absolute">
                              <p className="font-semibold text-red-500">
                                No Data Found
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </ConfigProvider>
          </div>
          <ToastContainer />
        </Fragment>
      )}
    </>
  );
};

export default RequirementList;
