import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Number from "../NumberCounter/Number";
import homeBG from "../../../images/homeBG.png";
import { Link, useNavigate } from "react-router-dom";
import { Empty, Tooltip, Pagination, ConfigProvider } from "antd";
import { useDispatch } from "react-redux";
import { Logout } from "../../../store/actions/AuthActions";
import swal from "sweetalert";

const Home = () => {
  let Navigate = useNavigate();

  const handleCopyClick = (email) => {
    const textarea = document.createElement("textarea");
    textarea.value = email;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand("copy");

    document.body.removeChild(textarea);
    toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Time Bar Logic
  const [StartDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );

  const [time, setTime] = useState("Today");
  const [isTodayDisabled, setIsTodayDisabled] = useState(false);
  const [dateRangeText, setDateRangeText] = useState(
    `${moment().format("DD/MM/YYYY")} - ${moment()
      .add(1, "days")
      .format("DD/MM/YYYY")}`
  );

  const sortByTime = [
    { name: "Today" },
    { name: "Weekly" },
    { name: "Monthly" },
  ];

  const initialStartDate = moment().startOf("day");
  const initialEndDate = moment().endOf("day");

  const handleDateRangeChange = (event, picker) => {
    if (picker.startDate.format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
      toast.error("You Cannot see the future!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setStartDate(picker.startDate.format("YYYY-MM-DD"));
      setEndDate(picker.endDate.format("YYYY-MM-DD"));
      setIsTodayDisabled(
        picker.startDate.format("YYYY-MM-DD") <=
          moment().format("YYYY-MM-DD") &&
          picker.endDate.format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
      );
      setDateRangeText(
        `${picker.startDate.format("DD/MM/YYYY")} - ${picker.endDate.format(
          "DD/MM/YYYY"
        )}`
      );
    }
  };

  const handleChangeDate = (el) => {
    setTime(el.name);
    // if (el.name === "Today") {
    //   setStartDate(moment().format("YYYY-MM-DD"));
    //   setEndDate(moment().add(1, "days").format("YYYY-MM-DD"));
    //   setDateRangeText(`${moment().format("DD/MM/YYYY")} - ${moment().add(1, "days").format("MM/DD/YYYY")}`);
    // } else if (el.name === "Weekly") {
    //   setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    //   setEndDate(moment().format("YYYY-MM-DD"));
    //   setDateRangeText(`${moment().subtract(7, "days").format("MM/DD/YYYY")} - ${moment().format("MM/DD/YYYY")}`);
    // } else if (el.name === "Monthly") {
    //   setStartDate(moment().subtract(30, "days").format("YYYY-MM-DD"));
    //   setEndDate(moment().format("YYYY-MM-DD"));
    //   setDateRangeText(`${moment().subtract(30, "days").format("MM/DD/YYYY")} - ${moment().format("MM/DD/YYYY")}`);
    // }

    setTime(el.name);
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    };
    const [start, end] = ranges[el.name];
    setStartDate(start);
    setEndDate(end);
    setDateRangeText(
      `${start.format("MMMM D, YYYY")} - ${end.format("MMMM D, YYYY")}`
    );
  };
  const predefinedRanges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));

  const [CountReg, setCountReg] = useState("");
  const [RegPage, setRegPage] = useState("");
  const [RegLimit, setRegLimit] = useState(10);
  const [CountExp, setCountExp] = useState("");
  const [ExpPage, setExpPage] = useState("");
  const [ExpLimit, setExpLimit] = useState(10);

  const onShowSizeChangeExp = (current, pageSize) => {
    setExpLimit(pageSize);
  };

  const onShowSizeChangeReg = (current, pageSize) => {
    setRegLimit(pageSize);
  };

  const [Data, setData] = useState();

  // const getAdminDashboardData = useCallback(async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   await fetch(
  //     process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
  //       `dashboard?startDate=${StartDate}&endDate=${EndDate}&Regpage=${RegPage}&Reglimit=${RegLimit}&Exppage=${ExpPage}&Explimit=${ExpLimit}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // console.log(result);
  //       if (result.message === "unauthorized" || result.message === "token expire") {
  //         swal({
  //           title: "Session Expired!",
  //           text: `your session has expired! Kindly login again!`,
  //           icon: "warning",
  //           dangerMode: true,
  //         }).then((willDelete) => {
  //           if (willDelete) {
  //             onLogout();
  //           } else {
  //             onLogout();
  //           }
  //         });
  //       } else {
  //         setData(result);
  //         setCountReg(result?.registrations?.totalRecords);
  //         setCountExp(result?.clientsExpiring?.totalRecords);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // }, [
  //   StartDate,
  //   EndDate,
  //   accessToken.accessToken,
  //   CountReg,
  //   RegPage,
  //   ExpPage,
  //   CountExp,
  //   RegLimit,
  //   ExpLimit,
  // ]);

  const getAdminDashboardData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `dashboard?startDate=${StartDate}&endDate=${EndDate}&Regpage=${RegPage}&Reglimit=${RegLimit}&Exppage=${ExpPage}&Explimit=${ExpLimit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result) {
          setData(result);
          setCountReg(result?.registrations?.totalRecords);
          setCountExp(result?.clientsExpiring?.totalRecords);
        } else if (result.message === "unauthorized" || result.message === "token expire") {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getAdminDashboardData();
  }, [
    EndDate,
    StartDate,
    accessToken.accessToken,
    CountReg,
    RegPage,
    ExpPage,
    CountExp,
    RegLimit,
    ExpLimit,
  ]);

  // Send Form

  const [IsLoading, setIsLoading] = useState({});

  const handleSendForm = useCallback(
    async (id) => {
      setIsLoading((prevLoadingState) => ({ ...prevLoadingState, [id]: true }));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        registrationId: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "requirements/create",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (
            result.message === "Requirement form has been sent through E-mail"
          ) {
            toast.success("Message has been sent to user", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getAdminDashboardData();
            setIsLoading((prevLoadingState) => ({
              ...prevLoadingState,
              [id]: false,
            }));
          } else {
            toast.error(`${result.message}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIsLoading((prevLoadingState) => ({
              ...prevLoadingState,
              [id]: false,
            }));
          }
        })
        .catch((error) => console.log("error", error));
    },
    [accessToken.accessToken, getAdminDashboardData]
  );

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  // API

  return (
    <div
      style={{
        // transform: "scale(.87)",
        transformOrigin: "0 0",
        // width: "90vw",
        // height: "",
      }}
      className="w-full xl:w-[90vw] xl:scale-[.87]"
    >
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            {/* calendar */}
            <div className="flex gap-[17px] justify-end">
              {/* <div className="p-2 rounded-[4px] bg-white mb-4 flex gap-2">
                {predefinedRanges.map((el, i) => {
                  return (
                    <button
                      className={`${
                        time === el.name
                          ? "bg-[#0aa1dd] p-1 rounded-[4px] px-2 text-md text-white font-semibold boxShadow"
                          : "text-[#0aa1dd] font-[500] text-md px-2"
                      } `}
                      key={i}
                      onClick={() => handleChangeDate(el)}
                      disabled={el.name === "Today" && isTodayDisabled}
                    >
                      {el.name}
                    </button>
                  );
                })}
              </div> */}
              <div className="p-2 rounded-[6px] bg-white mb-4 ">
                <DateRangePicker
                  initialSettings={{
                    startDate: initialStartDate.format("MM/DD/YYYY"),
                    endDate: initialEndDate.format("MM/DD/YYYY"),
                    ranges: predefinedRanges,
                  }}
                  onApply={handleDateRangeChange}
                >
                  <button className="text-[#0aa1dd] font-[500] text-md px-2 p-1 rounded-xl flex items-center gap-2">
                    {dateRangeText}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        fontWeight={"200"}
                      >
                        <path
                          d="M13.6 1.6H12V0.8C12 0.587827 11.9157 0.384344 11.7657 0.234315C11.6157 0.0842854 11.4122 0 11.2 0C10.9878 0 10.7843 0.0842854 10.6343 0.234315C10.4843 0.384344 10.4 0.587827 10.4 0.8V1.6H5.6V0.8C5.6 0.587827 5.51571 0.384344 5.36569 0.234315C5.21566 0.0842854 5.01217 0 4.8 0C4.58783 0 4.38434 0.0842854 4.23431 0.234315C4.08429 0.384344 4 0.587827 4 0.8V1.6H2.4C1.76348 1.6 1.15303 1.85286 0.702944 2.30294C0.252856 2.75303 0 3.36348 0 4V13.6C0 14.2365 0.252856 14.847 0.702944 15.2971C1.15303 15.7471 1.76348 16 2.4 16H13.6C14.2365 16 14.847 15.7471 15.2971 15.2971C15.7471 14.847 16 14.2365 16 13.6V4C16 3.36348 15.7471 2.75303 15.2971 2.30294C14.847 1.85286 14.2365 1.6 13.6 1.6ZM14.4 13.6C14.4 13.8122 14.3157 14.0157 14.1657 14.1657C14.0157 14.3157 13.8122 14.4 13.6 14.4H2.4C2.18783 14.4 1.98434 14.3157 1.83431 14.1657C1.68429 14.0157 1.6 13.8122 1.6 13.6V8H14.4V13.6ZM14.4 6.4H1.6V4C1.6 3.78783 1.68429 3.58434 1.83431 3.43431C1.98434 3.28429 2.18783 3.2 2.4 3.2H4V4C4 4.21217 4.08429 4.41566 4.23431 4.56569C4.38434 4.71571 4.58783 4.8 4.8 4.8C5.01217 4.8 5.21566 4.71571 5.36569 4.56569C5.51571 4.41566 5.6 4.21217 5.6 4V3.2H10.4V4C10.4 4.21217 10.4843 4.41566 10.6343 4.56569C10.7843 4.71571 10.9878 4.8 11.2 4.8C11.4122 4.8 11.6157 4.71571 11.7657 4.56569C11.9157 4.41566 12 4.21217 12 4V3.2H13.6C13.8122 3.2 14.0157 3.28429 14.1657 3.43431C14.3157 3.58434 14.4 3.78783 14.4 4V6.4Z"
                          fill="#0AA1DD"
                        />
                      </svg>
                    </span>
                  </button>
                </DateRangePicker>
              </div>
            </div>
            {/* Main Crds */}
            <div className="col-xl-6 h-full">
              <div className="row">
                <div className="col-xl-12" style={{}}>
                  <div
                    className="card h-auto "
                    style={{
                      backgroundImage: `url(${homeBG})`,
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      backgroundSize: "cover",
                      height: "100%",
                      paddingTop: "-10px",
                    }}
                  >
                    <div
                      className="card-body row py-20  "
                      style={{ margin: "32px 0px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 25,
                          marginTop: 30,
                        }}
                      >
                        <div>
                          <h4 style={{ color: "#fff" }}>
                            Number of Requirement Forms
                          </h4>
                          <Number
                            style={{
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: "55px",
                            }}
                            endValue={
                              Data?.totalRequirements === NaN
                                ? "-"
                                : Data?.totalRequirements
                            }
                          />
                        </div>
                        <div>
                          <h4 style={{ color: "#fff" }}>
                            Number of Registration Forms
                          </h4>
                          <Number
                            style={{
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: "55px",
                            }}
                            endValue={
                              Data?.totalRegistrations === NaN
                                ? "-"
                                : Data?.totalRegistrations
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-12 mb-4">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex flex-column p-4 justify-content-between">
                          <div>
                            <h4 className="text-lg font-w500 text-[#545454] mb-2">
                              Number of Requirement Forms To Be Submitted
                            </h4>
                            <div className="d-flex align-items-center py-1">
                              <Number
                                className="font-w700 mb-0 text-[40px]"
                                endValue={
                                  Data?.requirementsYetToBeSubmitted === NaN
                                    ? "-"
                                    : Data?.requirementsYetToBeSubmitted
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-sm-12 mb-4">
                      <div
                        className="card cardShadow"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex flex-column p-4 justify-content-between">
                          <div>
                            <h4 className="text-lg font-w500 text-[#545454] mb-2">
                              Expiring Clients
                            </h4>
                            <div className="d-flex align-items-center">
                              <Number
                                className="font-w700 mb-0 mt-[28px] text-[40px]"
                                endValue={
                                  Data?.expiringClients === NaN
                                    ? "-"
                                    : Data?.expiringClients
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-sm-12 mb-4">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex flex-column p-4 justify-content-between">
                          <div>
                            <h4 className="text-lg font-w500 text-[#545454] mb-2">
                              Clients Waiting For Activation
                            </h4>
                            <div className="d-flex align-items-center">
                              <Number
                                className="font-w700 mb-0 text-[40px]"
                                endValue={
                                  Data?.requirementsWaitingForActivation === NaN
                                    ? "-"
                                    : Data?.requirementsWaitingForActivation
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-sm-12 mb-4">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex flex-column p-4 justify-content-between">
                          <div>
                            <h4 className="text-lg font-w500 text-[#545454] mb-2">
                              Number Of Clients
                            </h4>
                            <div className="d-flex align-items-center">
                              <Number
                                className="font-w700 mb-0 mt-[28px] text-[40px]"
                                endValue={
                                  Data?.totalClients === NaN
                                    ? "-"
                                    : Data?.totalClients
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12  mb-4">
                      <div className="bg-[#fff] p-[15px] w-[675px] rounded-xl shadow-2xl d-flex justify-content-between align-items-center">
                        <div className="flex justify-centent-between">
                          <p className="text-lg font-w500  text-[#545454] mb-0">
                            Number of Transactions
                          </p>
                          <h2 className="fs-32 font-w700  mb-0">
                            <Number  endValue={Data?.numberOfTransactions} />
                          </h2>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-12 mb-4">
                      <div className="bg-white p-4 w-full rounded-xl shadow-2xl">
                        <div className="flex justify-between items-center">
                          <div className="text-lg font-medium  text-gray-600 mb-0">
                            Number of Transactions
                          </div>
                          <div className="text-4xl font-bold flex justify-between mb-0">
                            <div></div>
                            <Number
                              endValue={
                                Data?.numberOfTransactions === NaN
                                  ? "-"
                                  : Data?.numberOfTransactions
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3 mt-">
              {/* Buttons */}
              <div className="flex flex-col md:flex-col lg:flex-row gap-[18px] md:gap-[28px] mt-2 md:mt-0 lg:mt-0">
                {/* <div className="flex gap-5 items-center w-full md:w-full lg:w-1/2" /> */}
              </div>
              {/* Table */}
              <div className="flex flex-col md:flex-col lg:flex-row gap-[18px] md:gap-[28px] mt-[-20px] mb-5">
                <div className="bg-[#fff] p-5 rounded-xl shadow-2xl w-full md:w-full lg:w-1/2 xl:-mt-28">
                  <h1 className="font-semibold text-[#0aa1dd] py-2 text-2xl">
                    Registrations
                  </h1>
                  {/* <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-x-scroll">
                      <thead className="text-md text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-4 py-3">
                            Name
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Mobile
                          </th>
                          <th scope="col" className="px-4 py-3 w-6">
                            Email
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data &&
                          Data?.registrations?.length > 0 &&
                          Data.registrations.slice(0, 5).map((el, i) => {
                            return (
                              <tr className="bg-white border-b" key={i}>
                                <th
                                  scope="row"
                                  className="px-3 py-2 text-[#545454] text-nowrap"
                                >
                                  {el.User.fullname !== null
                                    ? el.User.fullname
                                    : "Not Mentioned"}
                                </th>
                                <td className="px-3 py-2 text-[#545454] text-nowrap">
                                  {el.User !== null
                                    ? el.User.mobileNumber
                                    : null}
                                </td>
                                <td
                                  className="px-3 py-2 text-[#545454] cursor-pointer"
                                  onClick={() => handleCopyClick(el.User.email)}
                                >
                                  <Tooltip
                                    title="Copy to clipboard"
                                    color="#0aa1dd"
                                  >
                                    <span>
                                      {el.User !== null
                                        ? el.User.email.slice(0, 19)
                                        : null}
                                    </span>
                                    ..
                                  </Tooltip>
                                </td>
                                <td className="px-3 py-2 text-[#545454]">
                                  {el.status === "submitted" ? (
                                    <Link
                                      to={`/requirement-form-list?req=${true}`}
                                      className="p-2 capitalize rounded-md bg-green-600 text-white text-center text-xs font-semibold w-[98px]"
                                    >
                                      {el.status}
                                    </Link>
                                  ) : el.status === "form sent" ? (
                                    <div className="p-2 capitalize rounded-md bg-orange-600 text-white text-center text-xs font-semibold w-[98px]">
                                      {el.status}
                                    </div>
                                  ) : (
                                    <button
                                      className="p-2 rounded-md bg-[#0aa1dd] text-white text-center text-xs font-semibold w-[98px]"
                                      onClick={() => handleSendForm(el.id)}
                                      disabled={IsLoading[el.id]}
                                    >
                                      {IsLoading[el.id] ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        "Send Form"
                                      )}
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {Data?.registrations?.length === 0 && (
                      <div
                        className="flex justify-center"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 30,
                        }}
                      >
                        <Empty />
                      </div>
                    )}
                  </div> */}
                  <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                      <thead className="text-md text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-4 py-3">
                            Name
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Mobile
                          </th>
                          <th scope="col" className="px-4 py-3 w-6">
                            Email
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {Data &&
                        Data.registrations &&
                        Data.registrations?.data?.length > 0 ? (
                          Data.registrations?.data.map((el, i) => (
                            <tr className="bg-white border-b " key={i}>
                              <th
                                scope="row"
                                className="px-3 py-2 text-[#545454] text-nowrap"
                              >
                                {el.User.fullname !== null
                                  ? el.User.fullname
                                  : "Not Mentioned"}
                              </th>
                              <td className="px-3 py-2 text-[#545454] text-nowrap">
                                {el.User !== null
                                  ? el.User.mobileNumber
                                  : "Not Mentioned"}
                              </td>
                              <td
                                className="px-3 py-2 text-[#545454] cursor-pointer"
                                onClick={() => handleCopyClick(el.User.email)}
                              >
                                <Tooltip
                                  title="Copy to clipboard"
                                  color="#0aa1dd"
                                >
                                  <span>
                                    {el.User !== null
                                      ? el.User.email.slice(0, 19)
                                      : "Not Mentioned"}
                                  </span>
                                  ..
                                </Tooltip>
                              </td>
                              <td className="px-3 py-2 text-[#545454]">
                                {el.status === "submitted" ? (
                                  <button
                                    onClick={() =>
                                      Navigate(
                                        `/requirement-form-list?req=${true}`
                                      )
                                    }
                                    className="p-2 rounded-md bg-green-600 text-white text-center text-xs font-semibold w-[98px]"
                                  >
                                    {el.status}
                                  </button>
                                ) : el.status === "form sent" ? (
                                  <button
                                    disabled
                                    className="p-2 capitalize rounded-md bg-orange-600 text-white text-center text-xs font-semibold w-[98px]"
                                  >
                                    {el.status}
                                  </button>
                                ) : (
                                  <button
                                    className="p-2 rounded-md bg-[#0aa1dd] text-white text-center text-xs font-semibold w-[98px]"
                                    onClick={() => handleSendForm(el.id)}
                                    disabled={IsLoading[el.id]}
                                  >
                                    {IsLoading[el.id] ? (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      "Send Form"
                                    )}
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="4"
                              className="px-4 py-3 text-md text-center text-gray-500"
                            >
                              No registrations found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="flex justify-end py-3 border-t">
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#0aa1dd",
                          },
                        }}
                      >
                        <Pagination
                          defaultCurrent={RegPage}
                          total={CountReg}
                          onChange={(val) => setRegPage(val)}
                          showSizeChanger
                          onShowSizeChange={onShowSizeChangeReg}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                  {Data && Data?.registrations?.length > 5 ? (
                    <Link
                      to="/requirement-form-list"
                      className="flex gap-2 justify-end py-1.5 hover:text-[#0aa1dd] transition-all duration-300"
                    >
                      <p className="font-semibold text-xs">Show More</p>
                      <span>
                        <i className="bi bi-arrow-right mt-1"></i>
                      </span>
                    </Link>
                  ) : (
                    false
                  )}
                </div>
                {/* <div className="bg-[#fff] p-5 rounded-xl shadow-2xl w-full md:w-full lg:w-1/2">
                  <h1 className="font-semibold text-[#0aa1dd] text-primary text-2xl py-2">
                    Expiring Clients Today
                  </h1>
                  <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right overflow-x-scroll">
                      <thead className="text-md text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Client Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Comapany Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Contact Number
                          </th>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Subscription End
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data &&
                          Data?.clientsExpiring?.length > 0 &&
                          Data.clientsExpiring.map((el, i) => {
                            console.log(el);
                            return (
                              <tr className="bg-white border-b" key={i}>
                                <th
                                  scope="row"
                                  className="px-3 py-[12.5px] font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {el.Users[0]?.fullname}
                                </th>
                                <td className="px-3 py-[12.5px]">{el.name}</td>
                                <td className="px-3 py-[12.5px]">
                                  {el.Users[0]?.mobileNumber}
                                </td>
                                <td className="px-3 py-[12.5px]">
                                  {moment(el.subscriptionEndDate).format(
                                    "DD-MM-YYYY, hh:mm a"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {Data?.clientsExpiring?.length === 0 && (
                      <div
                        className="flex justify-center"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 30,
                        }}
                      >
                        <Empty />
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="bg-[#fff] p-2 px-4 pb-5  rounded-xl shadow-2xl w-full md:w-full lg:w-1/2">
                  <h1 className="font-semibold text-[#0aa1dd] text-primary text-2xl py-2 px-1">
                    Expiring Clients
                  </h1>
                  <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right overflow-x-scroll">
                      <thead className="text-md text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Company Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Contact Number
                          </th>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Subscription
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data &&
                        Data.clientsExpiring &&
                        Data.clientsExpiring.data?.length > 0 ? (
                          Data.clientsExpiring?.data?.map((el, i) => (
                            <tr className="bg-white border-b" key={i}>
                              <th
                                scope="row"
                                className="px-3 py-[12.5px] font-medium text-gray-900 whitespace-nowrap"
                              >
                                {el.Users[0]?.fullname || "Not Mentioned"}
                              </th>
                              <td className="px-3 py-[12.5px]">
                                {el.name || "Not Mentioned"}
                              </td>
                              <td className="px-3 py-[12.5px]">
                                {el.Users[0]?.mobileNumber || "Not Mentioned"}
                              </td>
                              <td className="px-3 py-[12.5px]">
                                {el.subscriptionEndDate
                                  ? moment(el.subscriptionEndDate).format(
                                      "DD-MM-YYYY, hh:mm a"
                                    )
                                  : "Not Mentioned"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="4"
                              className="px-3 py-[12.5px] text-center text-md text-gray-500"
                            >
                              No clients expiring today
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="flex justify-end py-3 border-t">
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#0aa1dd",
                          },
                        }}
                      >
                        <Pagination
                          defaultCurrent={ExpPage}
                          total={CountExp}
                          onChange={(val) => setExpPage(val)}
                          showSizeChanger
                          onShowSizeChange={onShowSizeChangeExp}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Home;
