import React, { Fragment, useEffect, useState } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import {
  Link,
  useNavigate,
  // createSearchParams,
  useParams,
} from "react-router-dom";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { Image } from "antd";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";
import swal from "sweetalert"

const ClientProfile = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();
  let { id } = useParams();
  const [Data, setData] = useState([]);
  const [Role, setRole] = useState("");

  // Load
  const [loading, setloading] = useState(true);

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  useEffect(() => {
    const getProfile = async () => {
      setloading(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `users/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.user) {
          setData(result.user);
          setRole(result.user.role);
          setloading(false);
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.log("error", error));
    };

    getProfile();
  }, [accessToken.accessToken]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-[97vh] bg-white -m-10">
          <Spinner animation="border" variant="primary" className="mb-20" />
        </div>
      ) : (
        <div className="row animatedView">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content ">
                  <div className="cover-photo rounded"></div>
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    {/* <img
                      src={Data && Data.Client.logoUrl}
                      className="img-fluid rounded-circle"
                      style={{ border: "2px solid rgba(29, 84, 207, 1)" }}
                      alt="profile"
                    /> */}
                    <Image
                      width={100}
                      height={100}
                      src={Data && Data.Client.logoUrl}
                      className="rounded-full object-cover"
                    />
                  </div>
                  <div className="profile-details flex justify-between items-center">
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-primary mb-0">
                        {Data && Data.fullname}
                      </h4>
                      <p className="capitalize">{Role.replace(/[_\-]/g, " ").replace(/%[0-9A-F]{2}/g, " ").replace(/\d+/g, " ")}</p>
                    </div>
                    {/* <Link className="text-[#0aa1dd] text-[16px] font-semibold">
                    Reset Wallet Balance
                  </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-5 lg:gap-7">
            {/* Contact Details */}
            <div className="bg-white p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <h4 className="text-[#0aa1dd] text-[16px]">Contact</h4>
              </div>
              <div className="flex flex-col gap-[8px] mt-3">
                <div>
                  <h6 className="text-[#0aa1dd]">Full Name</h6>
                  <p className="text-[#9B9B9A] text-[12px]">
                    {Data && Data.fullname}
                  </p>
                </div>
                <div>
                  <h6 className="text-[#0aa1dd]">Mobile Number</h6>
                  <p className="text-[#9B9B9A] text-[12px]">
                    {Data && Data.mobileNumber}
                  </p>
                </div>
                <div>
                  <h6 className="text-[#0aa1dd]">Email</h6>
                  <p className="text-[#9B9B9A] text-[12px]">
                    {Data && Data.email}
                  </p>
                </div>
              </div>
            </div>
            {/* Company Details */}
            <div className="bg-white p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <h4 className="text-[#0aa1dd] text-[16px]">Company Details</h4>
              </div>
              <div className="flex flex-col gap-[8px] mt-3">
                <div>
                  <h6 className="text-[#0aa1dd]">Company Name</h6>
                  <p className="text-[#9B9B9A] text-[12px]">
                    {Data && Data.Client.name}
                  </p>
                </div>
                <div>
                  <h6 className="text-[#0aa1dd]">Company Address</h6>
                  <p className="text-[#9B9B9A] text-[12px] w-2/3">
                    {Data && Data.Client.address}
                  </p>
                </div>
              </div>
            </div>
            {/* Scoial Media */}
            <div className="bg-white p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <h4 className="text-[#0aa1dd] text-[16px]">Social Media</h4>
              </div>
              {/* icons */}
              <div className="mt-3 grid grid-cols-5 gap-2">
                {Data && Data.Client?.socialMediaAccount.includes(",") ? (
                  Data.Client?.socialMediaAccount
                    .split(",")
                    .map((link, index) => (
                      <Link
                        key={index}
                        to={link.trim()}
                        target="_blank"
                        className="flex justify-center"
                      >
                        {link.includes("twitter") ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60"
                            height="60"
                            viewBox="0 0 36 36"
                            fill="none"
                          >
                            <path
                              d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                              fill="white"
                            />
                            <path
                              d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                              fill="#1D9BF0"
                            />
                            <path
                              d="M28.0648 12.8547C28.0803 13.0778 28.0803 13.301 28.0803 13.5263C28.0803 20.3884 22.8563 28.3025 13.3041 28.3025V28.2982C10.4822 28.3023 7.71877 27.4941 5.34375 25.9702C5.75409 26.0196 6.16641 26.0442 6.57984 26.0452C8.91872 26.047 11.1903 25.2625 13.0296 23.8178C11.9459 23.7973 10.8957 23.4387 10.0258 22.7921C9.15595 22.1455 8.50986 21.2433 8.17791 20.2115C8.95595 20.3615 9.75805 20.3309 10.5224 20.122C8.09972 19.6325 6.35667 17.5039 6.35667 15.0317V14.9659C7.07891 15.3681 7.88721 15.5911 8.71355 15.6158C6.43177 14.0908 5.72836 11.0551 7.1062 8.68162C8.40998 10.286 10.0367 11.5982 11.8806 12.5329C13.7246 13.4677 15.7445 14.004 17.8093 14.1072C17.6034 13.221 17.6339 12.2964 17.8975 11.4256C18.1611 10.5549 18.6488 9.76867 19.3116 9.14554C21.4033 7.17932 24.6929 7.28015 26.6591 9.37068C27.8221 9.14107 28.9374 8.71472 29.9569 8.10998C29.5692 9.31241 28.758 10.3331 27.674 10.9821C28.7035 10.8608 29.7088 10.5852 30.6562 10.1646C29.9593 11.2084 29.0818 12.1192 28.0648 12.8547Z"
                              fill="white"
                            />
                          </svg>
                        ) : link.includes("facebook") ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60"
                            height="60"
                            viewBox="0 0 40 41"
                            fill="none"
                          >
                            <path
                              d="M40 20.4302C40 9.38455 31.0456 0.430176 20 0.430176C8.95437 0.430176 0 9.38455 0 20.4302C0 30.4127 7.31375 38.6869 16.875 40.1872V26.2114H11.7969V20.4302H16.875V16.0239C16.875 11.0114 19.8609 8.24267 24.4294 8.24267C26.6175 8.24267 28.9062 8.6333 28.9062 8.6333V13.5552H26.3844C23.8998 13.5552 23.125 15.0969 23.125 16.6786V20.4302H28.6719L27.7852 26.2114H23.125V40.1872C32.6862 38.6869 40 30.4128 40 20.4302Z"
                              fill="#1877F2"
                            />
                          </svg>
                        ) : link.includes("instagram") ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28.87 28.87"
                            width="60"
                            height="60"
                          >
                            <defs>
                              <linearGradient
                                id="a"
                                x1="-1.84"
                                x2="32.16"
                                y1="30.47"
                                y2="-3.03"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop
                                  offset="0"
                                  stop-color="#fed576"
                                  class="stopColorfed576 svgShape"
                                ></stop>
                                <stop
                                  offset=".26"
                                  stop-color="#f47133"
                                  class="stopColorf47133 svgShape"
                                ></stop>
                                <stop
                                  offset=".61"
                                  stop-color="#bc3081"
                                  class="stopColorbc3081 svgShape"
                                ></stop>
                                <stop
                                  offset="1"
                                  stop-color="#4c63d2"
                                  class="stopColor4c63d2 svgShape"
                                ></stop>
                              </linearGradient>
                            </defs>
                            <g
                              data-name="Layer 2"
                              fill="#000000"
                              class="color000 svgShape"
                            >
                              <g
                                data-name="Layer 1"
                                fill="#000000"
                                class="color000 svgShape"
                              >
                                <rect
                                  width="28.87"
                                  height="28.87"
                                  fill="url(#a)"
                                  rx="6.48"
                                  ry="6.48"
                                ></rect>
                                <g
                                  data-name="&amp;lt;Group&amp;gt;"
                                  fill="#000000"
                                  class="color000 svgShape"
                                >
                                  <path
                                    fill="#ffffff"
                                    d="M10 5h9c.2.1.5.1.7.2a4.78 4.78 0 0 1 3.8 3.3 8 8 0 0 1 .3 1.5v8.8a6.94 6.94 0 0 1-1.2 3.1 5.51 5.51 0 0 1-4.5 1.9h-7.5a5.49 5.49 0 0 1-3.7-1.2A5.51 5.51 0 0 1 5 18.14v-7a7.57 7.57 0 0 1 .1-1.5 4.9 4.9 0 0 1 3.8-4.3zm-3.1 9.5v3.9a3.42 3.42 0 0 0 3.7 3.7q3.9.15 7.8 0c2.3 0 3.6-1.4 3.7-3.7q.15-3.9 0-7.8a3.52 3.52 0 0 0-3.7-3.7q-3.9-.15-7.8 0a3.42 3.42 0 0 0-3.7 3.7z"
                                    data-name="&amp;lt;Compound Path&amp;gt;"
                                    class="colorfff svgShape"
                                  ></path>
                                  <path
                                    fill="#ffffff"
                                    d="M9.64 14.54a4.91 4.91 0 0 1 4.9-4.9 5 5 0 0 1 4.9 4.9 4.91 4.91 0 0 1-4.9 4.9 5 5 0 0 1-4.9-4.9zm4.9-3.1a3.05 3.05 0 1 0 3 3 3 3 0 0 0-3-3z"
                                    data-name="&amp;lt;Compound Path&amp;gt;"
                                    class="colorfff svgShape"
                                  ></path>
                                  <path
                                    fill="#ffffff"
                                    d="M18.34 9.44a1.16 1.16 0 0 1 1.2-1.2 1.29 1.29 0 0 1 1.2 1.2 1.2 1.2 0 0 1-2.4 0z"
                                    data-name="&amp;lt;Path&amp;gt;"
                                    class="colorfff svgShape"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        ) : (
                          link.includes("facebook")(
                            <p className="text-red-500 font-semibold">
                              Invalid social media link
                            </p>
                          )
                        )}
                      </Link>
                    ))
                ) : Data && Data?.Client?.socialMediaAccount ? (
                  <Link
                    to={Data && Data?.Client?.socialMediaAccount}
                    target="_blank"
                  >
                    {Data && Data?.socialMediaAccount?.includes("twitter") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                          fill="white"
                        />
                        <path
                          d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                          fill="#1D9BF0"
                        />
                        <path
                          d="M28.0648 12.8547C28.0803 13.0778 28.0803 13.301 28.0803 13.5263C28.0803 20.3884 22.8563 28.3025 13.3041 28.3025V28.2982C10.4822 28.3023 7.71877 27.4941 5.34375 25.9702C5.75409 26.0196 6.16641 26.0442 6.57984 26.0452C8.91872 26.047 11.1903 25.2625 13.0296 23.8178C11.9459 23.7973 10.8957 23.4387 10.0258 22.7921C9.15595 22.1455 8.50986 21.2433 8.17791 20.2115C8.95595 20.3615 9.75805 20.3309 10.5224 20.122C8.09972 19.6325 6.35667 17.5039 6.35667 15.0317V14.9659C7.07891 15.3681 7.88721 15.5911 8.71355 15.6158C6.43177 14.0908 5.72836 11.0551 7.1062 8.68162C8.40998 10.286 10.0367 11.5982 11.8806 12.5329C13.7246 13.4677 15.7445 14.004 17.8093 14.1072C17.6034 13.221 17.6339 12.2964 17.8975 11.4256C18.1611 10.5549 18.6488 9.76867 19.3116 9.14554C21.4033 7.17932 24.6929 7.28015 26.6591 9.37068C27.8221 9.14107 28.9374 8.71472 29.9569 8.10998C29.5692 9.31241 28.758 10.3331 27.674 10.9821C28.7035 10.8608 29.7088 10.5852 30.6562 10.1646C29.9593 11.2084 29.0818 12.1192 28.0648 12.8547Z"
                          fill="white"
                        />
                      </svg>
                    ) : Data &&
                      Data?.Client?.socialMediaAccount?.includes("facebook") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                      >
                        <path
                          d="M40 20.4302C40 9.38455 31.0456 0.430176 20 0.430176C8.95437 0.430176 0 9.38455 0 20.4302C0 30.4127 7.31375 38.6869 16.875 40.1872V26.2114H11.7969V20.4302H16.875V16.0239C16.875 11.0114 19.8609 8.24267 24.4294 8.24267C26.6175 8.24267 28.9062 8.6333 28.9062 8.6333V13.5552H26.3844C23.8998 13.5552 23.125 15.0969 23.125 16.6786V20.4302H28.6719L27.7852 26.2114H23.125V40.1872C32.6862 38.6869 40 30.4128 40 20.4302Z"
                          fill="#1877F2"
                        />
                      </svg>
                    ) : Data &&
                      Data?.Client?.socialMediaAccount?.includes(
                        "instagram"
                      ) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28.87 28.87"
                        width="36"
                        height="36"
                      >
                        <defs>
                          <linearGradient
                            id="a"
                            x1="-1.84"
                            x2="32.16"
                            y1="30.47"
                            y2="-3.03"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0"
                              stop-color="#fed576"
                              class="stopColorfed576 svgShape"
                            ></stop>
                            <stop
                              offset=".26"
                              stop-color="#f47133"
                              class="stopColorf47133 svgShape"
                            ></stop>
                            <stop
                              offset=".61"
                              stop-color="#bc3081"
                              class="stopColorbc3081 svgShape"
                            ></stop>
                            <stop
                              offset="1"
                              stop-color="#4c63d2"
                              class="stopColor4c63d2 svgShape"
                            ></stop>
                          </linearGradient>
                        </defs>
                        <g
                          data-name="Layer 2"
                          fill="#000000"
                          class="color000 svgShape"
                        >
                          <g
                            data-name="Layer 1"
                            fill="#000000"
                            class="color000 svgShape"
                          >
                            <rect
                              width="28.87"
                              height="28.87"
                              fill="url(#a)"
                              rx="6.48"
                              ry="6.48"
                            ></rect>
                            <g
                              data-name="&amp;lt;Group&amp;gt;"
                              fill="#000000"
                              class="color000 svgShape"
                            >
                              <path
                                fill="#ffffff"
                                d="M10 5h9c.2.1.5.1.7.2a4.78 4.78 0 0 1 3.8 3.3 8 8 0 0 1 .3 1.5v8.8a6.94 6.94 0 0 1-1.2 3.1 5.51 5.51 0 0 1-4.5 1.9h-7.5a5.49 5.49 0 0 1-3.7-1.2A5.51 5.51 0 0 1 5 18.14v-7a7.57 7.57 0 0 1 .1-1.5 4.9 4.9 0 0 1 3.8-4.3zm-3.1 9.5v3.9a3.42 3.42 0 0 0 3.7 3.7q3.9.15 7.8 0c2.3 0 3.6-1.4 3.7-3.7q.15-3.9 0-7.8a3.52 3.52 0 0 0-3.7-3.7q-3.9-.15-7.8 0a3.42 3.42 0 0 0-3.7 3.7z"
                                data-name="&amp;lt;Compound Path&amp;gt;"
                                class="colorfff svgShape"
                              ></path>
                              <path
                                fill="#ffffff"
                                d="M9.64 14.54a4.91 4.91 0 0 1 4.9-4.9 5 5 0 0 1 4.9 4.9 4.91 4.91 0 0 1-4.9 4.9 5 5 0 0 1-4.9-4.9zm4.9-3.1a3.05 3.05 0 1 0 3 3 3 3 0 0 0-3-3z"
                                data-name="&amp;lt;Compound Path&amp;gt;"
                                class="colorfff svgShape"
                              ></path>
                              <path
                                fill="#ffffff"
                                d="M18.34 9.44a1.16 1.16 0 0 1 1.2-1.2 1.29 1.29 0 0 1 1.2 1.2 1.2 1.2 0 0 1-2.4 0z"
                                data-name="&amp;lt;Path&amp;gt;"
                                class="colorfff svgShape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    ) : (
                      <p className="text-red-500 font-semibold">
                        Invalid social media link
                      </p>
                    )}
                  </Link>
                ) : (
                  <p className="text-red-500 font-semibold">
                    User has not passed any social media links
                  </p>
                )}
              </div>
            </div>
            {/* Expiry Date */}
            <div className="bg-white p-3 rounded-lg">
              <div>
                <h4 className="text-[#0aa1dd] text-[16px]">Subscription Expiry Date</h4>
                <p className="text-[#545454] text-[12px]">
                  {moment(Data && Data.Client?.subscriptionEndDate).format(
                    "YYYY-MM-DD, hh:mm A"
                  )}
                </p>
              </div>
            </div>
            {/* No. of Users */}
            <div className="bg-white p-3 rounded-lg">
              <div>
                <h4 className="text-[#0aa1dd] text-[16px]">Number of Users</h4>
                <p className="text-[#545454] text-[12px]">
                  {Data && Data.Client.numberOfUsers}
                </p>
              </div>
            </div>
          </div>
          {/* Button */}
          {/* <div className="my-5">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                background: "#0AA1DD",
                padding: "12px 13px",
                borderRadius: 5,
                border: "none",
                fontSize: 14,
                fontWeight: "normal",
                color: "#fff",
                gap: 5,
                marginTop: 40,
              }}
            >
              Reset Wallet Balance
            </button>
          </div> */}
        </div>
      )}
    </>
  );
};

export default ClientProfile;
