// import React from "react";
// import { useState, useEffect } from "react";
// import { Modal, Spinner } from "react-bootstrap";
// import swal from "sweetalert";
// import { DeleteOutlined } from "@ant-design/icons";

// const CheckList = () => {
//   let accessToken = JSON.parse(localStorage.getItem("userDetails"));
//   const [List, setList] = useState([]);
//   const [show, setShow] = useState(false);
//   const [showUpdate, setShowUpdate] = useState(false);
//   const [ChecklistName, setChecklistName] = useState("");
//   const [UpdateChecklistName, setUpdateChecklistName] = useState("");
//   const [UpdateChecklistID, setUpdateChecklistID] = useState("");
//   const [InputError, setInputError] = useState(false);
//   const getAllCheckList = async () => {
//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//     const requestOptions = {
//       method: "GET",
//       headers: myHeaders,
//       redirect: "follow",
//     };

//     await fetch(
//       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "checklist-items/",
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         console.log(result);
//         setList(result.checklists);
//       })
//       .catch((error) => console.error(error));
//   };
//   useEffect(() => {
//     getAllCheckList();
//   }, []);
//   const handleShow = () => setShow(true);
//   const handleClose = () => {
//     setShow(false);
//     setInputError(false);
//   };
//   const handleUpdateClose = () => {
//     setShowUpdate(false);
//     setInputError(false);
//   };
//   const createChecklist = () => {
//     if (ChecklistName === "") {
//       setInputError(true);
//     } else {
//       const myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");
//       myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//       const raw = JSON.stringify({
//         name: ChecklistName,
//         priority: 1,
//       });

//       const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "checklist-items/new",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           console.log(result);
//           swal("Created!", result.message, "success");
//           getAllCheckList();
//           setShow(false);
//         })
//         .catch((error) => console.error(error));
//     }
//   };
//   const update = (el) => {
//     setUpdateChecklistName(el.name);
//     setUpdateChecklistID(el.id);
//     setShowUpdate(true);
//   };
//   const updateCheckList = (id) => {
//     const myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//     const raw = JSON.stringify({
//       priority: 1,
//       name: UpdateChecklistName,
//     });

//     const requestOptions = {
//       method: "PUT",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
//         `checklist-items/edit/${id}`,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         console.log(result);
//         if (result.message === "Successfully updated") {
//           setShowUpdate(false);
//           swal("Updated", result.message, "warning");
//           getAllCheckList();
//         }
//       })
//       .catch((error) => console.error(error));
//   };
//   const deleteCheckList = (el) => {
//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//     const requestOptions = {
//       method: "DELETE",
//       headers: myHeaders,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
//         `checklist-items/remove/${el.id}`,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         // console.log(result);
//         if (result.message === "Successfully removed") {
//           getAllCheckList();
//           swal("Deleted", result.message, "error");
//         }
//       })
//       .catch((error) => console.error(error));
//   };

//   const ChecklistStatus = (id, status) => {
//     const myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//     const raw = JSON.stringify({
//       isActive: status === true ? false : status === false ? true : "",
//     });

//     const requestOptions = {
//       method: "PUT",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
//         `checklist-items/edit/${id}`,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         // console.log(result);
//         if (result.message === "Successfully updated") {
//           getAllCheckList();
//           swal(
//             status === false ? "Activate" : "Deactivate",
//             "Updated Successfully!",
//             "success"
//           );
//         }
//       })
//       .catch((error) => console.error(error));
//   };

//   return (
//     <div className="w-full">
//       <div className="flex flex-col gap-[17px]">
//         <h6 className="font-semibold text-sm text-black">Check List</h6>
//         <div className="bg-white p-3 rounded-xl relative">
//           {List?.map((el, i) => {
//             return (
//               <div
//                 className="form-check custom-checkbox mb-0 border-b mt-5"
//                 key={i}
//               >
//                 <div className="flex justify-between gap-10 items-center">
//                   <div className="">
//                     <input
//                       type="checkbox"
//                       className="form-check-input border-1 border-[#0aa1dd] mb-2"
//                       id={el.id}
//                       // value={el.id}
//                       checked={el.isActive}
//                       name="subscriptionGroup"
//                       onChange={() => ChecklistStatus(el.id, el.isActive)}
//                     />
//                     <label
//                       className="form-check-label text-[14px] text-[#545454] font-semibold"
//                       htmlFor={el.name}
//                     >
//                       {el.name}
//                     </label>
//                   </div>
//                   <div className="flex items-center gap-4 w-10">
//                     <button onClick={() => update(el)}>
//                       <i className="bi bi-pencil-square text-[#0aa1dd] text-base"></i>
//                     </button>
//                     {/* <button onClick={() => deleteCheckList(el)}>
//                       <DeleteOutlined className="text-red-500 text-lg" />
//                     </button> */}
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//           <div className="absolute top-2 right-3">
//             <button className="mb-2" onClick={handleShow}>
//               <i className="bi bi-plus-square-fill text-[#0aa1dd] text-lg font-semibold"></i>
//             </button>
//           </div>
//         </div>
//       </div>
//       {/* Creaate */}
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add Checklist.</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <label htmlFor="document">Enter checklist name:</label>
//           <div className="relative">
//             <input
//               type="text"
//               className={`${
//                 InputError === true
//                   ? "border border-red-500 placeholder:text-red-500"
//                   : "border border-gray-200"
//               } rounded-lg p-2 w-full`}
//               placeholder="eg. payment pending"
//               onChange={(e) => setChecklistName(e.target.value)}
//             />
//             {InputError === true && (
//               <div className="absolute top-2 right-2">
//                 <i className="bi bi-info-circle text-red-500"></i>
//               </div>
//             )}
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
//             onClick={handleClose}
//           >
//             Close
//           </button>
//           <button
//             className="bg-[#0aa1dd] text-white py-2.5 px-5 rounded-lg"
//             onClick={createChecklist}
//           >
//             Add Checklist
//           </button>
//         </Modal.Footer>
//       </Modal>
//       {/* Update */}
//       <Modal show={showUpdate} onHide={handleUpdateClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Update Checklist.</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <label htmlFor="document">Enter checklist name:</label>
//           <div className="relative">
//             <input
//               type="text"
//               className={`${
//                 InputError === true
//                   ? "border border-red-500 placeholder:text-red-500"
//                   : "border border-gray-200"
//               } rounded-lg p-2 w-full`}
//               placeholder="eg. payment pending"
//               value={UpdateChecklistName}
//               onChange={(e) => setUpdateChecklistName(e.target.value)}
//             />
//             {InputError === true && (
//               <div className="absolute top-2 right-2">
//                 <i className="bi bi-info-circle text-red-500"></i>
//               </div>
//             )}
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
//             onClick={handleUpdateClose}
//           >
//             Close
//           </button>
//           <button
//             className="bg-[#0aa1dd] text-white py-2.5 px-5 rounded-lg"
//             onClick={() => updateCheckList(UpdateChecklistID)}
//           >
//             Update Checklist
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default CheckList;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { DeleteOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../store/actions/AuthActions";

const CheckList = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  const [List, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [ChecklistName, setChecklistName] = useState("");
  const [UpdateChecklistName, setUpdateChecklistName] = useState("");
  const [UpdateChecklistID, setUpdateChecklistID] = useState("");
  const [InputError, setInputError] = useState(false);

  // const getAllCheckList = async () => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };
  //   console.log(process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL )

  //   await fetch(
  //     process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "checklist-items/",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //       setList(result.checklists);
  //     })
  //     .catch((error) => console.error(error));
  // };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  const getAllCheckList = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const url =
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "checklist-items/";

    try {
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      if (result.checklists) {
        setList(result.checklists);
      } else if (
        result.message === "Unauthorized!" ||
        result.message === "token expire"
      ) {
        swal({
          title: "Session Expired!",
          text: `your session has expired! Kindly login again!`,
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            onLogout();
          } else {
            onLogout();
          }
        });
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  useEffect(() => {
    getAllCheckList();
  }, []);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setInputError(false);
  };
  const handleUpdateClose = () => {
    setShowUpdate(false);
    setInputError(false);
  };

  const createChecklist = () => {
    if (ChecklistName === "") {
      setInputError(true);
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        name: ChecklistName,
        priority: 1,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "checklist-items/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (
            result.message === "unauthorized" ||
            result.message === "token expire"
          ) {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            swal("Created!", result.message, "success");
            getAllCheckList();
            setShow(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const update = (el) => {
    setUpdateChecklistName(el.name);
    setUpdateChecklistID(el.id);
    setShowUpdate(true);
  };

  const updateCheckList = (id) => {
    if (UpdateChecklistName === "") {
      setInputError(true);
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        priority: 1,
        name: UpdateChecklistName,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `checklist-items/edit/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully updated") {
            setShowUpdate(false);
            swal("Updated", result.message, "warning");
            getAllCheckList();
            setInputError(false);
          } else if (
            result.message === "unauthorized" ||
            result.message === "token expire"
          ) {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const deleteCheckList = (el) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `checklist-items/remove/${el.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.message === "Successfully removed") {
          getAllCheckList();
          swal("Deleted", result.message, "error");
        }
      })
      .catch((error) => console.error(error));
  };

  const ChecklistStatus = (id, status) => {
    const activeChecklistCount = List.filter((list) => list.isActive).length;

    // If there's only one active document and the user is trying to deactivate it
    if (activeChecklistCount === 1 && status) {
      swal("Error", "At least one checklist must be active.", "error");
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const raw = JSON.stringify({
      isActive: status === true ? false : status === false ? true : "",
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `checklist-items/edit/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.message === "Successfully updated") {
          getAllCheckList();
          swal(
            status === false ? "Activate" : "Deactivate",
            "Updated Successfully!",
            "success"
          );
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="w-full">
      <div className="flex flex-col  gap-[17px]">
        <h6 className="font-semibold text-[25px] text-black mt-5">
          Check List
        </h6>
        <div className="bg-white p-3 rounded-xl relative">
          <div className="mt-4">
            {List.length === 0 ? (
              <Empty />
            ) : (
              List.map((el, i) => {
                return (
                  <div
                    className="form-check custom-checkbox mb-2 p-2 px-4 border-b "
                    key={i}
                  >
                    <div className="flex justify-between gap-10 items-center">
                      <div className="">
                        <input
                          type="checkbox"
                          className="form-check-input border-1 border-[#0aa1dd] mb-2"
                          id={el.id}
                          checked={el.isActive}
                          name="subscriptionGroup"
                          onChange={() => ChecklistStatus(el.id, el.isActive)}
                          disabled={el.name === "Payment Received"}
                        />
                        <label
                          className="form-check-label text-[14px] text-[#545454] font-semibold"
                          htmlFor={el.name}
                        >
                          {el.name}
                        </label>
                      </div>
                      {el.name !== "Payment Received" && (
                        <div className="flex items-center gap-4 w-10">
                          <button onClick={() => update(el)}>
                            <i className="bi bi-pencil-square text-[#0aa1dd] text-base"></i>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="absolute top-2 right-3">
            <button className="mb-2" onClick={handleShow}>
              <i className="bi bi-plus-square-fill text-[#0aa1dd] text-lg font-semibold"></i>
            </button>
          </div>
        </div>
      </div>
      {/* Create */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Checklist.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="document">Enter checklist name:</label>
          <div className="relative">
            <input
              type="text"
              className={`${
                InputError === true
                  ? "border border-red-500 placeholder:text-red-500"
                  : "border border-gray-200"
              } rounded-lg p-2 w-full`}
              placeholder="eg. payment pending"
              onChange={(e) => setChecklistName(e.target.value)}
            />
            {InputError === true && (
              <div className="absolute top-2 right-2">
                <i className="bi bi-info-circle text-red-500"></i>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="bg-[#0aa1dd] text-white py-2.5 px-5 rounded-lg"
            onClick={createChecklist}
          >
            Add Checklist
          </button>
        </Modal.Footer>
      </Modal>
      {/* Update */}
      <Modal show={showUpdate} onHide={handleUpdateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Checklist.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="document">Enter checklist name:</label>
          <div className="relative">
            <input
              type="text"
              className={`${
                InputError === true
                  ? "border border-red-500 placeholder:text-red-500"
                  : "border border-gray-200"
              } rounded-lg p-2 w-full`}
              placeholder="eg. payment pending"
              value={UpdateChecklistName}
              onChange={(e) => setUpdateChecklistName(e.target.value)}
            />
            {InputError === true && (
              <div className="absolute top-2 right-2">
                <i className="bi bi-info-circle text-red-500"></i>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
            onClick={handleUpdateClose}
          >
            Close
          </button>
          <button
            className="bg-[#0aa1dd] text-white py-2.5 px-5 rounded-lg"
            onClick={() => updateCheckList(UpdateChecklistID)}
          >
            Update Checklist
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckList;
