import React, { useEffect, useState } from "react";
import { DatePicker, ConfigProvider, Pagination } from "antd";
import moment from "moment";
import { Empty } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "./Customer.css";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";
import swal from "sweetalert";

const Customers = () => {
  let { id } = useParams();
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();

  // Date Logic
  const [ExpireDate, setExpireDate] = useState("");
  const [StartDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  // Expire Date
  const handleExpireChange = (date, dateString) => {
    setExpireDate(dateString);
  };
  // Start Date
  const handleStartChange = (date, dateString) => {
    setStartDate(dateString);
  };
  // End Date
  const handleEndChange = (date, dateString) => {
    setEndDate(dateString);
  };

  const [CustomerData, setCustomerData] = useState([]);
  const [SearchLoad, setSearchLoad] = useState(false);
  const [SearchValue, setSearchValue] = useState("");
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(5);
  const [TotalCount, setTotalCount] = useState();

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  useEffect(() => {
    const getCustomerData = async () => {
      setSearchLoad(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `client/${id}/customers?q=${SearchValue}&startDate=${StartDate}&endDate=${EndDate}&page=${Page}&limit=${5}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.customers) {
            setSearchLoad(false);
            setCustomerData(result.customers);
            setTotalCount(result.totalRecords);
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.log("error", error));
      setSearchLoad(false);
    };
    getCustomerData();
  }, [accessToken.accessToken, SearchValue, StartDate, EndDate, Page, Limit]);

  const [ShowFilters, setShowFilters] = useState(false);

  return (
    <div>
      {/* Filter */}
      <div className="flex justify-between items-center">
        <button
          className="flex gap-2 items-center"
          onClick={() => setShowFilters(!ShowFilters)}
        >
          <span className="text-[#0AA1DD] text-sm">
            {ShowFilters ? "Hide Filters" : "Advance Filter"}
          </span>
          <span className="border-[2px] p-1 border-[#0aa1dd] rounded-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
            >
              <path
                d="M21.6425 0H0.330021C0.240051 0.00694095 0.156034 0.0476488 0.0948273 0.113956C0.033621 0.180262 -0.000246098 0.267263 2.12422e-05 0.3575V1.54C-0.000804896 1.6612 0.022474 1.78136 0.0685041 1.89348C0.114534 2.00561 0.182397 2.10746 0.268146 2.19312L8.51814 10.4431V17.3181L13.5025 19.8V10.4294L21.7525 2.17937C21.9103 2.00913 21.9986 1.78591 22 1.55375V0.3575C22 0.262685 21.9623 0.171754 21.8953 0.104709C21.8282 0.0376652 21.7373 0 21.6425 0Z"
                fill="#0AA1DD"
              />
            </svg>
          </span>
        </button>
      </div>
      {/* Grid Filters */}
      {ShowFilters && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9 animatedView my-4">
          {/* Start */}
          <div className="w-full flex flex-col">
            <label htmlFor="user" className="text-[#555] text-xs font-semibold">
              Created Date From
            </label>
            <div className="relative">
              <DatePicker
                onChange={handleStartChange}
                className="w-full p-2 px-3 placeholder:text-black placeholder:font-semibold border-none"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          {/* End */}
          <div className="w-full flex flex-col">
            <label htmlFor="user" className="text-[#555] text-xs font-semibold">
              Created Date To
            </label>
            <div className="relative">
              <DatePicker
                onChange={handleEndChange}
                className="w-full p-2 px-3 placeholder:text-black placeholder:font-semibold border-none"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
        </div>
      )}
      {/* Search */}
      <div className="flex justify-between">
        <div />
        <div className="relative">
          <input
            type="search"
            name="search"
            id=""
            className="p-2.5 w-[200px] md:w-[300px] bg-white rounded-lg"
            placeholder="search here..."
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {SearchLoad && (
            <div className="absolute top-3 right-16">
              <Spinner animation="border" size="sm" color="#0aa1dd" />
            </div>
          )}
          <div className="absolute top-0 right-0 p-2.5 px-3 bg-[#0aa1dd] rounded-r-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
            >
              <g
                data-name="Layer 2"
                fill="#ffffff"
                className="color000 svgShape"
              >
                <path
                  d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"
                  data-name="search"
                  fill="#ffffff"
                  className="color000 svgShape"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
      {/* {" Table"} */}
      <div className="relative overflow-x-auto">
        <table className="table text-gray-400 border-separate space-y-6 text-sm">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-3 py-4">No.</th>
              <th className="px-3 py-4 text-left">Customer Name</th>
              <th className="px-3 py-4 text-left">ID-Customer</th>
              <th className="px-3 py-4 text-left">Contact Number</th>
              <th className="px-3 py-4 text-left">Email</th>
            </tr>
          </thead>
          <tbody>
            {CustomerData?.length > 0 ? (
              CustomerData.map((el, i) => {
                return (
                  <tr
                    className="bg-transparent border-2 border-black lg:text-black text-base animatedView"
                    key={i}
                  >
                    <td className="p-3">{i + 1}</td>
                    <td className="p-3">{el.name}</td>
                    <td className="p-3 uppercase">{el.id}</td>
                    <td className="p-3 uppercase">{el.mobileNumber}</td>
                    <td className="p-3">{el.email}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-end py-3 border-t">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#0aa1dd",
              },
            }}
          >
            <Pagination
              defaultCurrent={Page}
              total={TotalCount}
              pageSize={Limit}
              onChange={(val) => setPage(val)}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default Customers;
